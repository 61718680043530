import React from "react";
import EsfInput from "../../components/EsfInput";
import User from "../../components/User";
import "../CompensationChange/style.css";
import SubmitButton from "../../components/SubmitButton";

const DayHourly = ({
    Notes, requestId, AutoID, reqDate, startTime, endTime, lunchOut, lunchIn, isLuchTaken, typeOfPayHourly,
    milageAmount, salaryOrHourly, action, setValuesPayHourOnChange, setDeleteRow, showLunchinOut, showDelete
}) => {

    let hiddenTxt = showDelete ? "" : "none";
    const optYesNo = [{ key: "--Select--", text: "--Select--" }, { key: "Yes", text: "Yes" }, { key: "No", text: "No" }];
    const optTypeOfPayHourly = [{ key: "--Select--", text: "--Select--" }, { key: "Regular Pay", text: "Regular Pay" }, { key: "Training", text: "Training" }, { key: "PTO", text: "PTO" },
    { key: "Sick Time", text: "Sick Time" }, { key: "Conference Call", text: "Conference Call" }, { key: "Drive Time", text: "Drive Time" },
    { key: "Holiday", text: "Holiday" }, { key: "Guaranteed Pay", text: "Guaranteed Pay" },]
    return (
        <>
            {(salaryOrHourly !== undefined && salaryOrHourly !== '' && action !== "delete") && (
                <div style={{ paddingBottom: '5px' }}>
                    <div style={{ border: 'solid 1px black' }}>
                        {(salaryOrHourly === 'Hourly') && (<p>{Notes}<br></br>
                            <EsfInput
                                type="date"
                                name="reqDate"
                                label="Date"
                                alert="Sundays only"
                                required
                                value={reqDate}
                                onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                            />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td><EsfInput
                                        label="Start time"
                                        value={startTime}
                                        type="time"
                                        name="startTime"
                                        required
                                        onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                    /></td>
                                    <td><EsfInput
                                        label="End time"
                                        value={endTime}
                                        type="time"
                                        name="endTime"
                                        required
                                        onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                    /></td>
                                </tr>
                                {(showLunchinOut) &&
                                    <tr>
                                        <td><EsfInput
                                            label="Lunch in"
                                            value={lunchIn}
                                            type="time"
                                            name="lunchIn"
                                            required
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                        /></td>
                                        <td><EsfInput
                                            label="Lunch out"
                                            value={lunchOut}
                                            type="time"
                                            name="lunchOut"
                                            required
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                        /></td>
                                    </tr>}
                                <tr>
                                    <td colSpan={2}>
                                        <EsfInput
                                            type="select"
                                            label="Did Employee Take Lunch?"
                                            required
                                            name="isLuchTaken"
                                            value={isLuchTaken}
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                            options={optYesNo}

                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <EsfInput
                                            type="select"
                                            label="Type Of Pay: "
                                            required
                                            name="typeOfPayHourly"
                                            value={typeOfPayHourly}
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                            options={optTypeOfPayHourly}

                                        />

                                    </td>
                                    <td>
                                        <EsfInput
                                            type="text"
                                            label="Number of Miles:"

                                            name="milageAmount"
                                            value={milageAmount}
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}

                                        />

                                    </td>
                                </tr>

                                <tr ><td colSpan={'2'} style={{ textAlign: 'right', display: hiddenTxt }}  >
                                    <button type="submit"
                                        onClick={(e) => setDeleteRow(e, AutoID)} style={{ color: 'white', backgroundColor: 'darkred', width: '150px', height: '35px' }}>
                                        Delete row
                                    </button></td>
                                </tr>
                            </table>

                        </p>)}



                    </div>

                </div>
            )}

        </>


    );
}

export default DayHourly;
