import { useEffect } from "react";

// This is your predefined object that maps column definitions per type of table.
const columnDefsMap = {
  "supervisor-change": [
    
    {
      headerName: "Comp Code",
      field: "company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: false,
      suppressToolPanel: true,
      width: 125,
    },
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 133,
    },
    {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    {
      headerName: "Program",
      field: "Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 102,
    },
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    {
      headerName: "New Supervisor",
      field: "New Supervisor",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 144,
    },
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },
    // ----------
    {
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
    {
      field: "newSupervisorId",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
  ],
  "compensation-change": [
    {
      headerName: "Comp Code",
      field: "Company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: false,
      suppressToolPanel: true,
      width: 125,
    },
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 133,
    },
    {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    {
      headerName: "Program",
      field: "Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 102,
    },
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    {
      headerName: "Action Type",
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 121,
    },
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },
    // ----------
    {
      field: "Supervisor ID",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
    
  ],
  "position-change": [
    // Effective Date
    {
      headerName: "Comp Code",
      field: "Company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: false,
      suppressToolPanel: true,
      width: 125,
    },
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 133,
    },
    // Employee Name
    {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    // Action Type
    {
      headerName: "Action Type",
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 121,
    },
    // Program
    {
      headerName: "Program",
      field: "Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 102,
    },
    // Position
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    // New Supervisor
    {
      headerName: "New Supervisor",
      field: "New Supervisor",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 144,
    },
    // State
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    // Business Unit
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    // Submitter Name
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    // Date Submitted
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },    
  ],
  "add-program": [
    {
      headerName: "Comp Code",
      field: "Company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: false,
      suppressToolPanel: true,
      width: 125,
    },
    // Effective Date
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 133,
    },
    // Employee Name
    {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    // Program
    {
      headerName: "Program",
      field: "Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 102,
    },
    // Position
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    // State
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    // Business Unit
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    // Submitter Name
    {
      headerName: "Submitter Name",
      field: "Submitter Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    // Date Submitted
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },
    // ----------
    {
      field: "Supervisor ID",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
    {
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
  ],
  transfer: [
    {
      headerName: "Comp Code",
      field: "Company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: false,
      suppressToolPanel: true,
      width: 125,
    },
    // Effective Date
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 133,
    },
    // Employee Name
    {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    // State
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    // Business Unit
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    // Current Program
    {
      headerName: "Current Program",
      field: "Current Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    // New Program
    {
      headerName: "New Program",
      field: "New Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 132,
    },
    // New Position
    {
      headerName: "New Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 127,
    },
    // New Supervisor
    {
      headerName: "New Supervisor",
      field: "New Supervisor",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 144,
    },
    // Submitter Name
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    // Date Submitted
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },
    {
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
  ],
  "payroll-request": [
    {
      headerName: "Comp Code",
      field: "Company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: false,
      suppressToolPanel: true,
      width: 125,
    },
   {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    {
      headerName: "Program",
      field: "Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 102,
    },
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    {
      headerName: "Action Type",
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 121,
    },
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },
    // ----------
    {
      field: "Supervisor ID",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
    
  ],
  "onboarding-queue": [
    // Assigned To
    {
      headerName: "Assigned To",
      field: "AssignedTo",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 124,
    },
    // Created
    {
      headerName: "Created",
      field: "Created",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 110,
    },
    // Employee ID
    {
      headerName: "Employee ID",
      field: "EmployeeID",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 127,
    },
    // Last Name
    {
      headerName: "Last Name",
      field: "LastName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 115,
    },
    // First Name
    {
      headerName: "First Name",
      field: "FirstName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 115,
    },
    // State
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    // Department
    {
      headerName: "Department",
      field: "Department",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 122,
    },
    // Position
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    // Business Unit
    {
      headerName: "Business Unit",
      field: "BusinessUnit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    // Employee Class
    {
      headerName: "Employee Class",
      field: "EmployeeClass",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    // Hire Date
    {
      headerName: "Hire Date",
      field: "HireDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 107,
    },
    // ----------
    {
      field: "STATUS",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
    {
      field: "Directsupervisor",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
  ],
  termination: [
    {
      headerName: "Comp Code",
      field: "Company",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      hide: false,
      enableRowGroup: true,
      width: 125,
    },
    // Effective Date
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 133,
    },
    // Employee Name
    {
      headerName: "Employee Name",
      field: "Employee Name",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 149,
    },
    // State
    {
      headerName: "State",
      field: "State",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 85,
    },
    // Business Unit
    {
      headerName: "Business Unit",
      field: "Business Unit",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 131,
    },
    // Program
    {
      headerName: "Program",
      field: "Program",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 102,
    },
    // Position
    {
      headerName: "Position",
      field: "Position",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 100,
    },
    // Submitter Name
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 146,
    },
    // Date Submitted
    {
      headerName: "Date Submitted",
      field: "dateSubmitted",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      width: 143,
    },
    // ----------
    {
      field: "Supervisor ID",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
    {
      field: "actionType",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: true,
      enableRowGroup: true,
      hide: true,
      suppressToolPanel: true,
    },
  ],
};

const useAgGridColumns = (gridApiRef, form) => {
  const setOverrideColumnDefs = (requestIdColumnDef) => {
    if (gridApiRef?.current?.api) {
      const columnDefs = columnDefsMap[form];
      if (columnDefs && requestIdColumnDef) {
        gridApiRef.current.api.setColumnDefs([
          ...requestIdColumnDef,
          ...columnDefs,
        ]);
      } else {
        console.warn(`No column definitions found for table type: ${form}`);
      }
    }
  };

  useEffect(() => {
    setOverrideColumnDefs();
  }, [gridApiRef, form]);

  return { setOverrideColumnDefs };
};

export default useAgGridColumns;
