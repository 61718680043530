import React from "react";
import EsfInput from "../../components/EsfInput";
import User from "../../components/User";
import "../CompensationChange/style.css";
import SubmitButton from "../../components/SubmitButton";

const DaySalary = ({
    Notes, requestId, AutoID, reqDate, typeOfPaySalary, typeOfDay, salaryOrHourly, action, setValuesPayHourOnChange, setDeleteRow, showDelete
}) => {

    let hiddenTxt = showDelete ? "" : "none";
    //const optYesNo = [{ key: "--Select--", text: "--Select--" }, { key: "Yes", text: "Yes" }, { key: "No", text: "No" }];
    const optTypeOfPaySalary = [{ key: "--Select--", text: "--Select--" }, { key: "Regular Pay", text: "Regular Pay" },
    { key: "PTO", text: "PTO" }, { key: "Holiday", text: "Holiday" }];
    const optTypeOfDay = [{ key: "--Select--", text: "--Select--" }, { key: "Full Day", text: "Full Day" },
    { key: "Half Day", text: "Half Day" }];
    return (
        <>
            {(salaryOrHourly !== undefined && salaryOrHourly !== '' && action !== "delete") && (
                <div style={{ paddingBottom: '5px' }}>
                    <div style={{ border: 'solid 1px black' }}>
                        {(salaryOrHourly === 'Salary') && (<p>{Notes}<br></br>

                            <table style={{ width: '100%' }}>

                                <tr>
                                    <td>
                                        <EsfInput
                                            type="date"
                                            name="reqDate"
                                            label="Date"
                                            alert="Sundays only"
                                            required
                                            value={reqDate}
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                        />
                                    </td>
                                    <td>
                                        <EsfInput
                                            type="select"
                                            label="Type of pay"
                                            required
                                            name="typeOfPaySalary"
                                            value={typeOfPaySalary}
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                            options={optTypeOfPaySalary}

                                        />

                                    </td>
                                    <td>
                                        <EsfInput
                                            type="select"
                                            label="Type of day"
                                            required
                                            name="typeOfDay"
                                            value={typeOfDay}
                                            onChange={(e) => setValuesPayHourOnChange(e, AutoID)}
                                            options={optTypeOfDay}

                                        />

                                    </td>
                                </tr>

                                <tr><td colSpan={'3'} style={{ textAlign: 'right', display: hiddenTxt }}>
                                    <button type="submit"
                                        onClick={(e) => setDeleteRow(e, AutoID)} style={{ color: 'white', backgroundColor: 'darkred', width: '150px', height: '35px' }}>
                                        Delete row
                                    </button></td>
                                </tr>
                            </table>

                        </p>)}



                    </div>

                </div>
            )}

        </>


    );
}

export default DaySalary;
