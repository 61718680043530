import React from "react";
import EsfInput from "../../components/EsfInput";
import "./style.css";
import User from "../../components/User";

const Approval = ({
    approvalFor, isDisabled, assignToLabel, assignToName, assignToValue, assignToOnChange,
    statusName, statusLabel, statusValue, statusOnChange,
    additionalCommName, additionalCommValue, additionalCommOnChange, additionalPayrollCommValue,
    submitOnClick }) => {
    let esgPayrollShowHide = false;
    const ApprovalOptions_CHR_AC_FIN = [{ key: "Pending Review", text: "Pending Review" },
    { key: "Approve", text: "Approve" },
    { key: "Reject to Submitter", text: "Reject to Submitter" }];


    const ApprovalOptions_ESG = [
        { key: "Pending Review", text: "Pending Review" },
        { key: "Approve & Complete", text: "Approve & Complete" },
        { key: "Approve & Submit to Payroll", text: "Approve & Submit to Payroll" }];
    const ApprovalOptions_PAY = [
        { key: "Pending Review", text: "Pending Review" },
        { key: "Complete", text: "Complete" }];
    const ApprovalOptions_SUP_EGS = [
        { key: "Pending Review", text: "Pending Review" },
        { key: "Approve & Complete", text: "Approve & Complete" }];
    const ApprovalOptions_SUP_PAY = [
        { key: "Pending Review", text: "Pending Review" },
        { key: "Complete", text: "Complete" }];
    var placeHolderText = "Comments", headerText;

    var ddlOptions = null;
    // if (approvalFor === "commercialhr" || approvalFor === "accounting" || approvalFor === "finance")
    //     ddlOptions = ApprovalOptions_CHR_AC_FIN;
    // else if (approvalFor === "esg")
    //     ddlOptions = ApprovalOptions_ESG;
    // else if (approvalFor === "payrollhr")
    //     ddlOptions = ApprovalOptions_PAY;
    // else if (approvalFor === "supesg")
    //     ddlOptions = ApprovalOptions_SUP_EGS;
    // else if (approvalFor === "subpayroll")
    //     ddlOptions = ApprovalOptions_SUP_PAY;
    switch (approvalFor) {
        case "commercialhr":
            ddlOptions = ApprovalOptions_CHR_AC_FIN;
            placeHolderText = "Comments";
            headerText = "Commercial HR Approval";
            break;
        case "billablehr":
            ddlOptions = ApprovalOptions_CHR_AC_FIN;
            placeHolderText = "Comments";
            headerText = "Billable HR Approval";
            break;
        case "accounting":
            ddlOptions = ApprovalOptions_CHR_AC_FIN;
            placeHolderText = "Comments"
            headerText = "Accounting Approval";
            break;
        case "finance":
            ddlOptions = ApprovalOptions_CHR_AC_FIN;
            placeHolderText = "Comments"
            headerText = "Finance Approval";
            break;
        case "esg":
            ddlOptions = ApprovalOptions_ESG;
            placeHolderText = "Comments"
            headerText = "ESG Approval";
            esgPayrollShowHide = true;
            break;
        case "payrollhr":
            ddlOptions = ApprovalOptions_PAY;
            placeHolderText = "Comments"
            headerText = "Payroll Approval";
            break;
        case "supesg":
            ddlOptions = ApprovalOptions_SUP_EGS;
            placeHolderText = "Comments"
            headerText = "ESG Approval";
            break;
        case "subpayroll":
            ddlOptions = ApprovalOptions_SUP_PAY;
            placeHolderText = "Comments"
            headerText = "Payroll Approval";
            break;
        case "payroll":
            ddlOptions = ApprovalOptions_CHR_AC_FIN;
            placeHolderText = "Comments"
            headerText = "Payroll Approval";
            break;
    }
    var isRequired = false;
    if (statusValue === "Pending Review" || statusValue === undefined || statusValue === null || statusValue.indexOf("Pending") >= 0) { isRequired = false; }
    else {
        isRequired = true;
    }
    return (
        <div className="main">
            <div className="outerDiv">
                <div>
                    <p>{headerText}</p>
                    <EsfInput
                        type="select"
                        name={statusName}
                        label={statusLabel}
                        value={statusValue}
                        onChange={statusOnChange}
                        options={ddlOptions}
                        disabled={isDisabled}
                        required
                    />
                    <User
                        label={assignToLabel}
                        name={assignToName}
                        required={isRequired}
                        value={assignToValue}
                        onChange={assignToOnChange}
                        disabled={isDisabled}
                        defaultSelectedUser={assignToValue ? [assignToValue] : []}
                    >
                    </User>
                    <EsfInput
                        type="textarea"
                        placeHolder={placeHolderText}
                        name={additionalCommName}
                        value={additionalCommValue}
                        onChange={additionalCommOnChange}
                        disabled={isDisabled}
                    />
                    {(esgPayrollShowHide &&
                        <EsfInput
                            type="textarea"
                            label={"Payroll Team Comments"}
                            placeHolder={placeHolderText}
                            name="additionalPayrollCommName"
                            value={additionalPayrollCommValue}
                            onChange={additionalCommOnChange}
                            disabled={isDisabled}
                        />
                    )}

                </div>
            </div>


        </div>
    );
}

export default Approval;
