//http://localhost:3000/compensation-change/63f40685
import React, { Component } from "react";
import "../CompensationChange/style.css";
import Title from "../../components/Title";
import Note from "../../components/Note";
import moment from "moment";
import EsfInput from "../../components/EsfInput";
import SubmitButton from "../../components/SubmitButton";
import { Navigate } from "react-router-dom";
import Toggle from '../../components/Toggle';
import EmployeeSearch from "../../components/EmployeeSearch";
import Swal from "sweetalert2";
import Approval from "../CompensationChange/approval";
import { requestStatus, userRoles, workflowHistory, employeeSearchText, employeeCompanyLocations } from "../../utils/constants";
import Grid from "../../components/Grid";
import { MsalContext } from "@azure/msal-react";
import { getTemporaryEmpId, postApi, postValuesApi, getImpersonatedUserRoles } from "../../utils/fetchApi";
import { appInsights } from "../../components/AppInsights";
import { cancelRequest, getFormattedDate, redirectUrl } from "../../utils/globalUtils";
import LoginAs from "../LoginAs";
import { getEmployeesFromSearch } from "../../utils/employeeService";
import DayHourly from "./DayHourly"
import DaySalary from "./DaySalary"
import { isAsyncThunkAction } from "@reduxjs/toolkit";

class PayrollRequest extends Component {
  static contextType = MsalContext;
  //Compensation Change Inside and out Cool Gray 7 = #9A9B9C
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isEmployeeLoading: false,
      isSupervisorLoading: false,
      goToHome: false,
      /*Form Values*/
      requestId: "",
      /*Approval States*/
      PayrollRequestFormValues: {
        requestId: '',
        completedDate: undefined,
        Created: undefined,
        dateSubmitted: undefined,
        Modified: undefined,
        payrollApproval: '',
        requestStatus: '',
        submitterEmail: '',
        submitterName: '',
        submitterPhone: '',
        CreatedBy: '',
        ModifiedBy: '',
        payrollAssignedTo: '',
        payrollComments: '',
        payrollAssignedToEmail: '',
        loginAsEmpId: '',
        loggedInAsDisplayName: '',
        loggedInAsEmail: '',
        isEmployee: '',
        payrollEndingDate: '',
        payrollEndingDate: undefined,
        SubmitterComments: '',
        Employee: {
          actionReason: '',
          effectiveDate: undefined,
          empBusinessUnit: '',
          empClass: '',
          EmployeeClass: '',//For Employee Control
          empCompany: '',
          CompanyID: '',
          CompanyName: '',
          empEmail: '',
          EmailPreferred: '',
          empHireDate: '',
          empId: '',
          empName: '',
          Name: '',
          EmployeeID: '',
          empLocation: '',
          empName: '',
          empPosition: '',
          empState: '',
          HomeState: '',
          empSupervisor: '',
          empTitle: '',
          businessTitle: '',
          JobDescription: '', // For Employee Searcg Drop down that was created later and needs different valuesHireDate: '',
          DepartmentDescription: '',
          SupervisorID: '',
          isSupervisor: "",
        },
        //Employee: undefined,
        SuperVisor: {
          actionReason: '',
          effectiveDate: undefined,
          empBusinessUnit: '',
          empClass: '',
          EmployeeClass: '',//For Employee Control
          empCompany: '',
          CompanyID: '',
          CompanyName: '',
          empEmail: '',
          EmailPreferred: '',
          empHireDate: '',
          empId: '',
          empName: '',
          Name: '',
          EmployeeID: '',
          empLocation: '',
          empName: '',
          empPosition: '',
          empState: '',
          HomeState: '',
          empSupervisor: '',
          empTitle: '',
          businessTitle: '',
          JobDescription: '', // For Employee Searcg Drop down that was created later and needs different valuesHireDate: '',
          DepartmentDescription: '',
          SupervisorID: '',
          isSupervisor: "",
        },
        //SuperVisor: undefined,
        PayrollRequestPayHours: [{
          AutoID: "1",
          requestId: '',
          reqDate: '',
          startTime: '',
          endTime: '',
          lunchOut: '',
          lunchIn: '',
          isLuchTaken: '',
          typeOfPayHourly: '',
          milageAmount: '',
          typeOfPaySalary: '',
          typeOfDay: '',
          salaryOrHourly: '',
          action: 'add'
        }],
      },


      SelectedEmployee: {
        empBusinessUnit: '',
        empClass: '',
        EmployeeClass: '',//For Employee Control
        empCompany: '',
        CompanyID: '',
        empEmail: '',
        EmailPreferred: '',
        empHireDate: '',
        empId: '',
        empName: '',
        EmployeeID: '',
        empLocation: '',
        empName: '',
        empPosition: '',
        empState: '',
        empSupervisor: '',
        empTitle: '',
        businessTitle: '',
        JobDescription: '', // For Employee Searcg Drop down that was created later and needs different valuesHireDate: '',
        SupervisorID: '',
        isSupervisor: "",
      },
      SelectedEmployee: undefined,
      SelectedSupervisor: {
        empBusinessUnit: '',
        empClass: '',
        EmployeeClass: '',//For Employee Control
        empCompany: '',
        CompanyID: '',
        empEmail: '',
        EmailPreferred: '',
        empHireDate: '',
        empId: '',
        empName: '',
        EmployeeID: '',
        empLocation: '',
        empName: '',
        empPosition: '',
        empState: '',
        empSupervisor: '',
        empTitle: '',
        businessTitle: '',
        JobDescription: '', // For Employee Searcg Drop down that was created later and needs different valuesHireDate: '',
        SupervisorID: '',
        isSupervisor: "",
      },
      SelectedSupervisor: undefined,

      searchQueryEmp: '',
      searchQuerySup: '',
      EmployeeList: [],
      SuperVisorList: [],

      EmployeeNewCompanyID: '',
      EmployeeNewCompanyLocation: '',
      SuperVisorNewCompanyID: '',
      SuperVisorNewCompanyLocation: '',

      /*payrollApproval: null,
      payrollAssignedTo: null,
      payrollAssignedToEmail: null,
      payrollComments: null,
      payrollAssignedToDisplay: null,*/

      /*Approval workflow*/
      gridRows: [],
      gridColumns: [],

      roles: '',
      isCancelVisible: false,
      isFormValidSubmitToSave: false,
      isNavigateToApprovalQueue: false,
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', DisplayEmail: null },
      EmployeeCompanyLocations: employeeCompanyLocations


    };
    this.RequestStatus = {
      PendingHR: requestStatus.pendingCommercialHR,
      RejectHR: requestStatus.rejectToSubmitter,
      PendingBillableHR: requestStatus.pendingBillableHR,
      RejectBillableHR: requestStatus.rejectToSubmitter,
      PendingAccount: requestStatus.pendingAccounting,
      RejectToSubmitter: requestStatus.rejectToSubmitter,
      PendingFinance: requestStatus.pendingFinance,
      RejectFinance: requestStatus.rejectToSubmitter,
      PendingESG: requestStatus.pendingESG,
      PendingPayroll: requestStatus.pendingPayroll,
      Complete: requestStatus.complete,
      Cancelled: requestStatus.cancelled
    };

    this.isEmployeeOptions = [{ key: "Select", text: "Select" },
    { key: "Hourly", text: "Hourly" },
    { key: "Salary", text: "Salary" }];
    this.LoggedInUser = {
    }
    this.roles = {}
    this.userRoles = userRoles;
    this.EmployeeCompanyLocations = employeeCompanyLocations;
  }

  /*  Start - Page Event  */
  componentDidMount = () => {
    const start = new Date().getTime();
    const autAccount = this.context.accounts[0];
    if (autAccount && autAccount.name)
      this.LoggedInUser = { DisplayName: autAccount.name, UserName: autAccount.username, IsSubmitter: false, IsInAnyRole: false, IsAdminRole: false }
    try {
      let roles = [];
      const userAccess = JSON.parse(localStorage.getItem("UserAccess"));
      if (userAccess) {
        var isESFOwner = false;
        if (userAccess.roles.some(role => (role.name === this.userRoles.esfOwners))) { isESFOwner = true; }
        this.setState({ roles: userAccess.roles, isESFOwner: isESFOwner });
        this.roles = userAccess.roles;
        console.log("My Roles", this.roles);
        if (userAccess.employeeID && userAccess.employeeID.length > 0 && userAccess.employeeID[0].employeeId) {
          this.LoggedInUser.EmployeeID = userAccess.employeeID[0].employeeId;
        }
      }
    } catch (err) {
      console.log(err);
    }

    //"00203655"; Class:EXT-- Company:MSU--Business Unit:81
    //"05378281"; Class:CNS-- Company:MSC--Business Unit:83
    //"02507999"; Class:CNS-- Company:MSU--Business Unit:83
    //"00210115"; Class:INT-- Company:TEM--Business Unit:1
    //"03806371"; Class:INT-- Company:MSU--Business Unit:81
    //06983234 Russel,Erica ; Brice, Jessica MSU - BU 82
    //06146962 06983234 Russel,Erica ; Brice, Jessica MSU - BU 82
    this.getDropdownOptions().then((result) => {
      const actionReason = result.ResultSets.Table1?.map((val) => {
        return { key: val.ActionReason, text: val.ActionReason };
      });
      this.setState({ reasonForPayChangeOptions: [...[{ key: "--Choose Reason--", text: "--Choose Reason--" }], ...actionReason] });
    });

    this.getBarerToken().then((token) => {
      var empID = this.LoggedInUser.EmployeeID ? this.LoggedInUser.EmployeeID : "" //: "02507999" //For Production "02507999"
      //empID = "00203655" // For Dev Only remove for prod;
      if (getTemporaryEmpId(this.LoggedInUser.UserName)) {
        empID = getTemporaryEmpId(this.LoggedInUser.UserName);
      };
      //empID = "05378281"; // For Dev Only remove for prod;

      /* Login as other user*/
      if (this.state.loggedInAs && this.state.loggedInAs.EmpId) {
        empID = this.state.loggedInAs.EmpId;
      }

      if (window.location.href.indexOf('/payroll-request/') > 0) {
        var reqid = window.location.href.toString().substring(window.location.href.lastIndexOf('/payroll-request/') + '/payroll-request/'.length);
        if (reqid) {
          //this.validateUserAccess();
          //if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
          this.getRequestByRequestId(token, reqid).then(() => {

            if (this.LoggedInUser.IsSubmitter || this.LoggedInUser.IsInAnyRole || this.LoggedInUser.IsAdminRole) {
              this.getWorkflowHistory(reqid);
            }

          });
          //}


        }
      }
    })

    if (appInsights) {
      appInsights.trackEvent({
        name: `Page Load Compensation Change`,
        properties: {
          TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
        },
      });
    }

  };

  cancelVisibility = async (roles, status) => {
    this.setState({ isCancelVisible: false });
    if (status !== requestStatus.cancelled && status !== requestStatus.complete) {
      const rolesAllowed = process.env.REACT_APP_CANCELLATION_ROLES_ALLOWED;
      if (roles.some(role => rolesAllowed.includes(role.name))) {
        this.setState({ isCancelVisible: true });
      }
    }
  }

  async getDropdownOptions() {
    return postValuesApi(
      process.env.REACT_APP_ESF_GETCHOICEVALUES,
      {
        RelatedProcess: "Payroll Request",
      }
    );
  };

  getBarerToken = async () => {
    const response = await postApi(process.env.REACT_APP_ESF_BEARERTOKEN, { ResourceID: process.env.REACT_APP_ESF_EMP_RESOURCEID });
    return "Bearer " + response.access_token;

  }

  getRequestByRequestId = async (token, requestid) => {

    try {
      let reqResponse = await fetch(process.env.REACT_APP_ESF_PAYROLLREQUEST, {
        method: "POST",
        headers:
        {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            Method: "READ",
            requestID: requestid,
            PayrollRequestData: {}
          }
        ),
      });
      let reqResponseJson = await reqResponse.json();
      if (reqResponseJson != null && reqResponseJson != undefined &&
        reqResponseJson.ResultSets != null && reqResponseJson.ResultSets != undefined &&
        reqResponseJson.ResultSets.Table1 != null && reqResponseJson.ResultSets.Table1 != undefined

      ) {
        //#region - form Values
        let requestId, isEmployee
        let reqFrmValues = reqResponseJson.ResultSets.Table1[0];
        console.log(reqFrmValues);
        requestId = reqFrmValues.requestId;
        isEmployee = reqFrmValues.isEmployee;
        let frmValues = this.state.PayrollRequestFormValues;
        frmValues.Created = reqFrmValues.Created;
        frmValues.CreatedBy = reqFrmValues.CreatedBy;
        frmValues.Modified = reqFrmValues.Modified;
        frmValues.ModifiedBy = reqFrmValues.ModifiedBy;
        frmValues.completedDate = reqFrmValues.completedDate;
        frmValues.dateSubmitted = reqFrmValues.dateSubmitted;

        frmValues.isEmployee = reqFrmValues.isEmployee;
        frmValues.loggedInAsDisplayName = reqFrmValues.loggedInAsDisplayName;
        frmValues.loggedInAsEmail = reqFrmValues.loggedInAsEmail;
        frmValues.loginAsEmpId = reqFrmValues.loginAsEmpId;
        frmValues.payrollApproval = reqFrmValues.payrollApproval;
        frmValues.payrollAssignedTo = reqFrmValues.payrollAssignedTo;
        frmValues.payrollAssignedToEmail = reqFrmValues.payrollAssignedToEmail;
        frmValues.payrollComments = reqFrmValues.payrollComments;
        frmValues.payrollEndingDate = moment(reqFrmValues.payrollEndingDate).format("M/D/YYYY")
        frmValues.requestId = reqFrmValues.requestId;

        frmValues.requestStatus = reqFrmValues.requestStatus;
        frmValues.submitterEmail = reqFrmValues.submitterEmail;
        frmValues.submitterName = reqFrmValues.submitterName;
        frmValues.submitterPhone = reqFrmValues.submitterPhone;
        frmValues.SubmitterComments = reqFrmValues.SubmitterComments ? reqFrmValues.SubmitterComments : "";

        let reqEmployee = frmValues.Employee;

        reqEmployee.actionReason = reqFrmValues.actionReason;
        reqEmployee.effectiveDate = reqFrmValues.effectiveDate ? reqFrmValues.effectiveDate : undefined;
        reqEmployee.empBusinessUnit = reqFrmValues.empBusinessUnit;
        reqEmployee.empClass = reqFrmValues.empClass;
        reqEmployee.EmployeeClass = reqFrmValues.empClass;
        reqEmployee.empCompany = reqFrmValues.empCompany;
        reqEmployee.CompanyID = reqFrmValues.empCompany;
        reqEmployee.CompanyName = this.EmployeeCompanyLocations.filter(item => item.key === reqFrmValues.empCompany)?.[0]?.text;
        reqEmployee.empEmail = reqFrmValues.empEmail;
        reqEmployee.EmailPreferred = reqFrmValues.empEmail;
        reqEmployee.HireDate = reqFrmValues.empHireDate;
        reqEmployee.empId = reqFrmValues.empId;
        reqEmployee.EmployeeID = reqFrmValues.empId;
        reqEmployee.empLocation = reqFrmValues.empLocation
        reqEmployee.empName = reqFrmValues.empName;
        reqEmployee.Name = reqFrmValues.empName;
        reqEmployee.empPosition = reqFrmValues.empPosition;
        reqEmployee.empState = reqFrmValues.empState;
        reqEmployee.HomeState = reqFrmValues.empState;

        reqEmployee.SupervisorID = reqFrmValues.empSupervisor;//check
        //reqEmployee.SuperVisor = reqFrmValues.empSupervisor;//check
        reqEmployee.empSupervisor = reqFrmValues.empSupervisor
        reqEmployee.businessTitle = reqFrmValues.empTitle;
        reqEmployee.empTitle = reqFrmValues.empTitle;
        reqEmployee.JobDescription = reqFrmValues.empTitle;
        reqEmployee.DepartmentDescription = reqFrmValues.empDepartmentDescription;
        frmValues.Employee = reqEmployee

        let reqSupervisor = frmValues.SuperVisor;
        reqSupervisor.empBusinessUnit = reqFrmValues.supBusinessUnit;
        reqSupervisor.empClass = reqFrmValues.supClass;
        reqSupervisor.EmployeeClass = reqFrmValues.supClass;
        reqSupervisor.empCompany = reqFrmValues.supCompany;
        reqSupervisor.CompanyID = reqFrmValues.supCompany;
        reqSupervisor.CompanyName = this.EmployeeCompanyLocations.filter(item => item.key === reqFrmValues.supCompany)?.[0]?.text;
        reqSupervisor.empEmail = reqFrmValues.supEmail;
        reqSupervisor.EmailPreferred = reqFrmValues.supEmail;
        reqSupervisor.HireDate = reqFrmValues.supHireDate;
        reqSupervisor.empId = reqFrmValues.supEmpId;
        reqSupervisor.EmployeeID = reqFrmValues.supEmpId;
        reqSupervisor.empLocation = reqFrmValues.supLocation
        reqSupervisor.empName = reqFrmValues.supName;
        reqSupervisor.Name = reqFrmValues.supName;
        reqSupervisor.empPosition = reqFrmValues.supPosition;
        reqSupervisor.empState = reqFrmValues.supState;
        reqSupervisor.HomeState = reqFrmValues.supState;
        reqSupervisor.SupervisorID = reqFrmValues.supSupervisor;//check
        //reqSupervisor.SuperVisor = reqFrmValues.supSupervisor;//check
        reqSupervisor.empSupervisor = reqFrmValues.supSupervisor
        reqSupervisor.businessTitle = reqFrmValues.supTitle;
        reqSupervisor.empTitle = reqFrmValues.supTitle;
        reqSupervisor.JobDescription = reqFrmValues.supTitle;
        reqSupervisor.DepartmentDescription = reqFrmValues.supDepartmentDescription;
        frmValues.SuperVisor = reqSupervisor
        //#endregion

        //#region Pay values

        if (reqResponseJson.ResultSets.Table2 != null && reqResponseJson.ResultSets.Table2 != undefined) {
          let reqReqPayHours = reqResponseJson.ResultSets.Table2;
          console.log("Table2", reqReqPayHours);

          frmValues.PayrollRequestPayHours = [];
          reqResponseJson.ResultSets.Table2.map((o, key) => {
            frmValues.PayrollRequestPayHours.push({
              AutoID: String(reqResponseJson.ResultSets.Table2[key].AutoID),
              requestId: requestId,
              reqDate: reqResponseJson.ResultSets.Table2[key].reqDate,
              startTime: reqResponseJson.ResultSets.Table2[key].startTime,
              endTime: reqResponseJson.ResultSets.Table2[key].endTime,
              lunchOut: reqResponseJson.ResultSets.Table2[key].lunchOut,
              lunchIn: reqResponseJson.ResultSets.Table2[key].lunchIn,
              isLuchTaken: reqResponseJson.ResultSets.Table2[key].isLuchTaken,
              typeOfPayHourly: reqResponseJson.ResultSets.Table2[key].typeOfPayHourly,
              milageAmount: reqResponseJson.ResultSets.Table2[key].milageAmount,
              typeOfPaySalary: reqResponseJson.ResultSets.Table2[key].typeOfPaySalary,
              typeOfDay: reqResponseJson.ResultSets.Table2[key].typeOfDay,
              salaryOrHourly: isEmployee,
              action: 'update'

            })
          })
        }

        //#endregion
        this.validateUserAccess(reqResponseJson.ResultSets.Table1[0]);
        this.setState({
          requestid: reqFrmValues.requestId,
          PayrollRequestFormValues: frmValues,
          SelectedEmployee: reqEmployee,
          SelectedSupervisor: reqSupervisor
        }, () => this.setFormValidationStatus())
        this.cancelVisibility(this.state.roles, frmValues.requestStatus);
      }



    }
    catch (err) {
      console.log(err);
    }



  }

  getWorkflowHistory = async (requestId) => {

    try {
      if (requestId) {
        let reqResponse = await fetch(process.env.REACT_APP_ESF_WORKFLOW_HISTORY, {
          method: "POST",
          headers:
          {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            {
              Method: "READ",
              requestID: requestId,
              formName: "PayrollRequest"
            }
          ),
        });
        let reqResponseJson = await reqResponse.json();
        //console.log(reqResponseJson);
        const historyResult = reqResponseJson.ResultSets.Table1;
        if (historyResult && historyResult.length > 0) {
          const headers = Object.keys(historyResult[0]).map((col) => {
            return { field: col, filter: true, floatingFilter: true, sortable: true }
          });
          this.setState({ gridRows: historyResult, gridColumns: headers });
        }
      }
    }
    catch (err) {
      console.log(err)
    };

  }

  getImpersonated = (e) => {
    if (e !== null && e.detail !== null && e.detail.length > 0 && e.detail[0].userPrincipalName !== null &&
      (this.state.requestId == '' || this.state.requestId == undefined || this.state.requestId == null)) {
      this.setState({ loading: true });
      getImpersonatedUserRoles(e.detail[0].userPrincipalName).then(() => {
        var temp = localStorage.getItem("LoggedInAsUserAccess");
        if (temp) {
          temp = JSON.parse(temp);
          console.log(temp);
          this.setState({
            loggedInAs: { EmpId: temp.employeeID[0].employeeId, DisplayName: e.detail[0].displayName, Email: e.detail[0].userPrincipalName, LoginAsError: false },
            loading: false
          }, () => { this.componentDidMount() });
        } else {
          this.setState({ LoginAsError: true });
          localStorage.removeItem("LoggedInAsUserAccess");
          this.setState({
            loggedInAs: { EmpId: '', DisplayName: '', Email: '' },
            loading: false
          })
        }


      });

    } else if (!this.state.requestId) {
      localStorage.removeItem("LoggedInAsUserAccess");
      this.setState({
        loggedInAs: { EmpId: '', DisplayName: '', Email: '' },
        loading: false
      })
    }


  }

  clearImpersonation = (e) => {
    localStorage.removeItem("LoggedInAsUserAccess");
    this.setState({
      loggedInAs: { EmpId: '', DisplayName: '', Email: '', },
      Employees: [],
      selectedEmployee: null
    }, () => { this.componentDidMount() });

  }

  validateUserAccess = (reqJsonValues) => {
    var IsSubmitter = false, IsInAnyRole = false, IsAdminRole = false;
    /* Is Logged in User is Submitter */
    if (this.LoggedInUser.UserName.trim().toLowerCase() === reqJsonValues.submitterEmail.trim().toLowerCase()) {
      IsSubmitter = true;
    }
    /* Is user is in any of the approval role*/
    if (this.roles.some(role => (role.name === this.userRoles.commercialHR)) || this.roles.some(role => (role.name === this.userRoles.hrBillable)) || this.roles.some(role => (role.name === this.userRoles.accounting)) ||
      this.roles.some(role => (role.name === this.userRoles.finance))
      || this.roles.some(role => (role.name === this.userRoles.esgInternal)) || this.roles.some(role => (role.name === this.userRoles.esgBillable))
      || this.roles.some(role => (role.name === this.userRoles.payrollInternal)) || this.roles.some(role => (role.name === this.userRoles.payrollBillable))
      || this.roles.some(role => (role.name === this.userRoles.payrollCanada))) {
      IsInAnyRole = true;
    }
    if (this.roles.some(role => (role.name === this.userRoles.esfOwners)) || this.roles.some(role => (role.name === this.userRoles.adminInternal))
      || this.roles.some(role => (role.name === this.userRoles.adminBillable))) {
      IsAdminRole = true;
    }
    this.LoggedInUser.IsSubmitter = IsSubmitter;
    this.LoggedInUser.IsInAnyRole = IsInAnyRole;
    this.LoggedInUser.IsAdminRole = IsAdminRole;
    console.log("LoggedInUser", this.LoggedInUser);
  }

  handleCompanyCountryChangeEmp(e) {

    let oChanges = this.state.PayrollRequestFormValues;
    oChanges.Employee.empCompany = this.EmployeeCompanyLocations.filter(item => item.text === e.target.value)?.[0]?.key;
    oChanges.Employee.CompanyID = this.EmployeeCompanyLocations.filter(item => item.text === e.target.value)?.[0]?.key;
    oChanges.Employee.CompanyName = e.target.value
    //EmployeeNewCompanyLocation: e.target.value

    this.setState({
      EmployeeList: [],//, SelectedEmployee: undefined,
      PayrollRequestFormValues: oChanges,
      SelectedEmployee: undefined
    }, () => this.setFormValidationStatus());

  }
  handleCompanyCountryChangeSup(e) {

    let oChanges = this.state.PayrollRequestFormValues;
    oChanges.SuperVisor.empCompany = this.EmployeeCompanyLocations.filter(item => item.text === e.target.value)?.[0]?.key;
    oChanges.SuperVisor.CompanyID = this.EmployeeCompanyLocations.filter(item => item.text === e.target.value)?.[0]?.key;
    oChanges.SuperVisor.CompanyName = e.target.value
    //EmployeeNewCompanyLocation: e.target.value

    this.setState({
      SuperVisorList: [],//, SelectedEmployee: undefined,
      PayrollRequestFormValues: oChanges,
      SelectedSupervisor: undefined
    }, () => this.setFormValidationStatus());
    /*this.setState({
      SuperVisorList: [], SelectedSupervisor: undefined,
      SuperVisorNewCompanyID: this.EmployeeCompanyLocations.filter(item => item.text === e.target.value)?.[0]?.key,
      SuperVisorNewCompanyLocation: e.target.value
    });*/

  }


  handleSelectedEmployee = (emp) => {
    let oValuesToChange = this.state.PayrollRequestFormValues;
    let oEmp = oValuesToChange.Employee;
    if (emp !== null) {
      oEmp = {
        empBusinessUnit: '',
        actionReason: emp.ActionReason ? emp.ActionReason : '',
        effectiveDate: emp.EffectiveDate ? emp.EffectiveDate : undefined,
        empClass: emp.EmployeeClass,
        EmployeeClass: emp.EmployeeClass,//For Employee Control
        empCompany: this.state.PayrollRequestFormValues.Employee.empCompany,
        CompanyID: this.state.PayrollRequestFormValues.Employee.CompanyID,
        CompanyName: this.state.PayrollRequestFormValues.Employee.CompanyName,
        empEmail: emp.EmailBusiness,
        EmailPreferred: emp.EmailBusiness,
        empHireDate: emp.HireDate ? emp.HireDate : undefined,
        empId: emp.EmployeeID,
        empName: emp.name,
        Name: emp.name,
        EmployeeID: emp.EmployeeID,
        empLocation: emp.HomeState,
        empName: emp.Name,
        empPosition: emp.JobDescription,
        empState: emp.HomeState,
        HomeState: emp.HomeState,
        empSupervisor: emp.SupervisorID,
        empTitle: emp.JobDescription,
        businessTitle: emp.JobDescription,
        JobDescription: emp.JobDescription, // For Employee Searcg Drop down that was created later and needs different valuesHireDate: '',
        DepartmentDescription: emp.DepartmentDescription + "-" + emp.DepartmentID,
        SupervisorID: emp.SupervisorID,
        isSupervisor: emp.IsSupervisor,
      };
      oValuesToChange.Employee = oEmp;
    } else {
      oEmp = [];
    }


    this.setState({
      SelectedEmployee: emp,
      PayrollRequestFormValues: oValuesToChange
    }, () => this.setFormValidationStatus());

  }
  handleSelectedSupervisor = (emp) => {

    let oValuesToChange = this.state.PayrollRequestFormValues;
    let oEmp = this.state.PayrollRequestFormValues.SuperVisor;
    if (emp !== null) {
      oEmp = {
        empBusinessUnit: '',
        empClass: emp.EmployeeClass,
        EmployeeClass: emp.EmployeeClass,//For Employee Control
        empCompany: this.state.PayrollRequestFormValues.SuperVisor.empCompany,
        CompanyID: this.state.PayrollRequestFormValues.SuperVisor.CompanyID,
        CompanyName: this.state.PayrollRequestFormValues.SuperVisor.CompanyName,
        empEmail: emp.EmailBusiness,
        EmailPreferred: emp.EmailBusiness,
        empHireDate: emp.HireDate,
        empId: emp.EmployeeID,
        empName: emp.name,
        Name: emp.name,
        EmployeeID: emp.EmployeeID,
        empLocation: emp.HomeState,
        empName: emp.Name,
        empPosition: emp.JobDescription,
        empState: emp.HomeState,
        HomeState: emp.HomeState,
        empSupervisor: emp.SupervisorID,
        empTitle: emp.JobDescription,
        businessTitle: emp.JobDescription,
        JobDescription: emp.JobDescription, // For Employee Searcg Drop down that was created later and needs different valuesHireDate: '',
        DepartmentDescription: emp.DepartmentDescription + "-" + emp.DepartmentID,
        SupervisorID: emp.SupervisorID,
        isSupervisor: emp.IsSupervisor,
      };
    } else {
      oEmp = [];
    }

    oValuesToChange.SuperVisor = oEmp;

    this.setState({
      SelectedSupervisor: emp,
      PayrollRequestFormValues: oValuesToChange
    }, () => this.setFormValidationStatus());

    /*this.setState({
      SelectedSupervisor: emp,
    }, () => this.getFormValidationStatus());*/

  }


  handleSetSearchQueryEmp = async (val) => {
    this.setState({ searchQueryEmp: val });

    if (val !== undefined && val !== null && val.length > 2) {

      try {
        this.setState({ isEmployeeLoading: true });
        const controller = new AbortController();
        const { signal } = controller;
        //this.getNewSupervisors(val, this.state.SelectedSuperVisorCompanyID);
        const empResponse = await getEmployeesFromSearch(val, this.state.PayrollRequestFormValues.Employee.empCompany, signal);
        if (empResponse) {
          console.log(empResponse);
          this.setState({ EmployeeList: empResponse });

        }
        this.setState({ isEmployeeLoading: false });
      } catch (err) {
        console.log(err);
        this.setState({ isEmployeeLoading: false });
      }

    } else { this.setState({ isEmployeeLoading: false }); }

  }
  handleSetSearchQuerySup = async (val) => {
    this.setState({ searchQuerySup: val });

    if (val !== undefined && val !== null && val.length > 2) {

      try {
        this.setState({ isSupervisorLoading: true });
        const controller = new AbortController();
        const { signal } = controller;
        //this.getNewSupervisors(val, this.state.SelectedSuperVisorCompanyID);
        const empResponse = await getEmployeesFromSearch(val, this.state.PayrollRequestFormValues.SuperVisor.empCompany, signal);
        if (empResponse) {
          console.log(empResponse);
          this.setState({ SuperVisorList: empResponse });

        }
        this.setState({ isSupervisorLoading: false });
      } catch (err) {
        console.log(err);
        this.setState({ isSupervisorLoading: false });
      }

    } else { this.setState({ isSupervisorLoading: false }); }

  }

  setComponentStatus = (e) => {
    if (e) {
      let o = this.state.PayrollRequestFormValues;
      switch (e.target.name) {

        case "isEmployee":

          o.isEmployee = e.target.value;
          this.setState({ PayrollRequestFormValues: o }, () => this.setFormValidationStatus());
          break;

        case "payrollEndingDate":
          try {
            if (this.validateSaturday(moment(e.target.value).format("M/D/YYYY"))) {
              o.payrollEndingDate = moment(e.target.value).format("M/D/YYYY");

              this.setState({
                PayrollRequestFormValues: o,
                //payrollEndingDate: moment(e.target.value).format("M/D/YYYY"),
                dateLblClass: "labelNormal"
              }, () => this.setFormValidationStatus());
            }
            else {
              o.payrollEndingDate = null;
              this.setState({
                PayrollRequestFormValues: o,
                dateLblClass: "labelError"
              }, () => this.setFormValidationStatus());
            }
          } catch (err) {
            o.payrollEndingDate = null;
            this.setState({
              PayrollRequestFormValues: o,
              dateLblClass: "labelError"
            }, () => this.setFormValidationStatus());
          }
          break;

        case "payrollapprovalstatus":
          o.payrollApproval = e.target.value;
          this.setState({ PayrollRequestFormValues: o }, () => this.setFormValidationStatus());
          break;
        case "payrolladdcomm":
          o.payrollComments = e.target.value;
          this.setState({ PayrollRequestFormValues: o }, () => this.setFormValidationStatus());
          break;
        case "payrollassignto":
          var email = e.detail.length > 0 ? (e.detail[0].mail ? e.detail[0].mail : e.detail[0].userPrincipalName) : undefined;
          var displayName = e.detail.length > 0 ? (e.detail[0].displayName ? e.detail[0].displayName : undefined) : undefined;
          if (!email) {
            this.setState({ payrollAssignedToDisplay: undefined })
          }
          o.payrollAssignedToEmail = email;
          o.payrollAssignedTo = displayName;

          this.setState({
            PayrollRequestFormValues: o
          }, () => this.setFormValidationStatus());
          break;
        case "SubmitterComments":
          o.SubmitterComments = e.target.value;
          this.setState({ PayrollRequestFormValues: o });
      }
    }

  };

  validateSaturday = (dat) => {
    try {
      dat = new Date(dat);
      var weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      if (weekdays[dat.getDay()] !== "Saturday") {
        return false;
      }
      else {
        return true;
      }
    } catch (err) {
      return false;
    }
  };



  addBlankRowToPayrollRequestPayHours() {
    let oChanges = this.state.PayrollRequestFormValues;
    let AutoID = (oChanges.PayrollRequestPayHours.length + 1) + "";
    oChanges.PayrollRequestPayHours.push({
      AutoID: String(AutoID),
      requestId: '',
      reqDate: '',
      startTime: '',
      endTime: '',
      lunchOut: '',
      lunchIn: '',
      isLuchTaken: '',
      typeOfPayHourly: '',
      milageAmount: '',
      typeOfPaySalary: '',
      typeOfDay: '',
      salaryOrHourly: this.state.PayrollRequestFormValues.isEmployee,
      action: 'add'
    });
    this.setState({
      PayrollRequestFormValues: oChanges
    }, () => this.setFormValidationStatus());
  }

  setValuesPayHourOnChange(e, AutoId) {
    let Parent = this.state.PayrollRequestFormValues
    let val = this.state.PayrollRequestFormValues.PayrollRequestPayHours;
    //val = AutoId

    let indexToReplace = val.findIndex((o) => o.AutoID === AutoId);
    console.log(indexToReplace);
    if (indexToReplace > -1) {

      switch (e.target.name) {
        case "reqDate":
          try { val[indexToReplace].reqDate = moment(e.target.value).format("M/D/YYYY") }
          catch (e) {
          }
          break;

        case "startTime":
          val[indexToReplace].startTime = e.target.value;
          break;
        case "endTime":
          val[indexToReplace].endTime = e.target.value;
          break;
        case "lunchIn":
          val[indexToReplace].lunchIn = e.target.value;
          break;
        case "lunchOut":
          val[indexToReplace].lunchOut = e.target.value;
          break;
        case "isLuchTaken":
          val[indexToReplace].isLuchTaken = e.target.value;
          break;
        case "typeOfPayHourly":
          val[indexToReplace].typeOfPayHourly = e.target.value;
          break;
        case "milageAmount":
          val[indexToReplace].milageAmount = e.target.value;
          break;
        case "typeOfPaySalary":
          val[indexToReplace].typeOfPaySalary = e.target.value;
          break;
        case "typeOfDay":
          val[indexToReplace].typeOfDay = e.target.value;
          break;
      }
      Parent.PayrollRequestPayHours = val;
      this.setState({ PayrollRequestFormValues: Parent }, () => this.setFormValidationStatus());
    }



    //alert('value');

  }

  setDeleteRow(e, AutoId) {

    //alert(AutoId);
    let Parent = this.state.PayrollRequestFormValues
    let val = this.state.PayrollRequestFormValues.PayrollRequestPayHours;
    //val = AutoId

    let indexToReplace = val.findIndex((o) => o.AutoID === AutoId);
    console.log(indexToReplace);
    if (indexToReplace > -1) {

      val[indexToReplace].reqDate = undefined;
      val[indexToReplace].startTime = "";
      val[indexToReplace].endTime = e.target.value;
      val[indexToReplace].lunchIn = e.target.value;
      val[indexToReplace].lunchOut = e.target.value;
      val[indexToReplace].isLuchTaken = e.target.value;
      val[indexToReplace].typeOfPayHourly = e.target.value;
      val[indexToReplace].milageAmount = e.target.value;
      val[indexToReplace].typeOfPaySalary = e.target.value;
      val[indexToReplace].typeOfDay = e.target.value;
      val[indexToReplace].action = "delete";

      Parent.PayrollRequestPayHours = val;
      this.setState({ PayrollRequestFormValues: Parent }, () => this.setFormValidationStatus());
    }





  }

  handleSubmitToSave = async (action) => {
    const start = new Date().getTime();
    this.setState({ goToHome: false });
    var validation = this.setFormValidationStatus();// Not Completed
    if (validation.status) {

      if (action === "create") {

        var requestId = Math.floor(Math.random() * 1677721555).toString(16);
        //let reqBodyValue = this.state.PayrollRequestFormValues;
        let reqBodyValue = {
          requestId: requestId,
          requestStatus: this.RequestStatus.PendingPayroll,
          submitterEmail: this.LoggedInUser.UserName,
          submitterName: this.LoggedInUser.DisplayName,
          CreatedBy: this.LoggedInUser.DisplayName,
          ModifiedBy: this.LoggedInUser.DisplayName,
          Created: getFormattedDate(new Date()),//moment(new Date()).format("M/D/YYYY"),//new Date(),//
          dateSubmitted: getFormattedDate(new Date()),//moment(new Date()).format("M/D/YYYY"),
          Modified: getFormattedDate(new Date()),
          payrollEndingDate: this.state.PayrollRequestFormValues.payrollEndingDate,
          isEmployee: this.state.PayrollRequestFormValues.isEmployee,

          empBusinessUnit: this.state.PayrollRequestFormValues.Employee.empBusinessUnit,
          empClass: this.state.PayrollRequestFormValues.Employee.empClass,
          actionReason: this.state.PayrollRequestFormValues.Employee.actionReason,
          effectiveDate: this.state.PayrollRequestFormValues.Employee.effectiveDate,
          empCompany: this.state.PayrollRequestFormValues.Employee.empCompany,
          CompanyName: this.state.PayrollRequestFormValues.Employee.empCompany,
          empEmail: this.state.PayrollRequestFormValues.Employee.empEmail,
          empHireDate: this.state.PayrollRequestFormValues.Employee.empHireDate,
          empId: this.state.PayrollRequestFormValues.Employee.empId,
          empLocation: this.state.PayrollRequestFormValues.Employee.empLocation,
          empName: this.state.PayrollRequestFormValues.Employee.empName,
          Name: this.state.PayrollRequestFormValues.Employee.empName,
          empPosition: this.state.PayrollRequestFormValues.Employee.empPosition,
          empState: this.state.PayrollRequestFormValues.Employee.empState,
          HomeState: this.state.PayrollRequestFormValues.Employee.empState,
          empSupervisor: this.state.PayrollRequestFormValues.Employee.empSupervisor,
          empTitle: this.state.PayrollRequestFormValues.Employee.empTitle,
          JobDescription: this.state.PayrollRequestFormValues.Employee.empTitle,
          empDepartmentDescription: this.state.PayrollRequestFormValues.Employee.DepartmentDescription,

          supBusinessUnit: this.state.PayrollRequestFormValues.SuperVisor.empBusinessUnit,
          supClass: this.state.PayrollRequestFormValues.SuperVisor.empClass,
          supCompany: this.state.PayrollRequestFormValues.SuperVisor.empCompany,
          CompanyName: this.state.PayrollRequestFormValues.SuperVisor.empCompany,
          supEmail: this.state.PayrollRequestFormValues.SuperVisor.empEmail,
          supHireDate: this.state.PayrollRequestFormValues.SuperVisor.empHireDate,
          supEmpId: this.state.PayrollRequestFormValues.SuperVisor.empId,
          supLocation: this.state.PayrollRequestFormValues.SuperVisor.empLocation,
          supName: this.state.PayrollRequestFormValues.SuperVisor.empName,
          supPosition: this.state.PayrollRequestFormValues.SuperVisor.empPosition,
          supState: this.state.PayrollRequestFormValues.SuperVisor.empState,
          HomeState: this.state.PayrollRequestFormValues.SuperVisor.empState,
          supSupervisor: this.state.PayrollRequestFormValues.SuperVisor.empSupervisor,
          supTitle: this.state.PayrollRequestFormValues.SuperVisor.empTitle,
          supDepartmentDescription: this.state.PayrollRequestFormValues.SuperVisor.DepartmentDescription,
          SubmitterComments: this.state.PayrollRequestFormValues.SubmitterComments,
          PayRollData: this.state.PayrollRequestFormValues.PayrollRequestPayHours,


        }

        try {
          var SaveURL = process.env.REACT_APP_ESF_PAYROLLREQUEST;
          let respPayRollReq = await fetch(SaveURL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              Method: "CREATE",
              requestID: requestId,
              PayrollRequestData: reqBodyValue
            }),
          });
          let creationStaus = await respPayRollReq.json();

          if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
            Swal.fire({
              title: "Something went wrong...",
              html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
              icon: "error",
            });
            console.error(creationStaus.error);
          } else {
            if (!Object.keys(creationStaus).includes('error')) {
              let oTemp = this.state.PayrollRequestFormValues;
              oTemp.requestId = requestId;
              this.setState({ PayrollRequestFormValues: oTemp }, () => {


                Swal
                  .fire({
                    title: "Submitted Successfully",
                    text: "Your request has been submitted and is pending for applicable approvals",
                    icon: "success",
                    html: `<a href="/" >Go to Home</a>`,
                    // showCancelButton: true,
                    confirmButtonText: "View Details",
                    //cancelButtonText: "No, cancel!",
                    // reverseButtons: true,
                  })
                  .then(async (result) => {
                    if (result.isConfirmed) {
                      this.setState({ goToHome: true });;
                    }
                  });
              });
            }

          }
          document.getElementById("lblError").innerHTML = "";

          if (appInsights) {
            appInsights.trackEvent({
              name: `Page Submit Compensation Change`,
              properties: {
                TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
              },
            });
          }

        } catch (err) {
          console.log(err);
          //window.location.href = "compensation-change/SuccessError/0";
          document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
          this.setState({
            //saveStatus: "error",
            requestId: 0,
          });
        }

      }
      else if (action = 'resubmit') {
        //var requestId = Math.floor(Math.random() * 1677721555).toString(16);
        //let reqBodyValue = this.state.PayrollRequestFormValues;
        let o = this.state.PayrollRequestFormValues;
        let reqBodyValue = {
          requestId: o.requestId,
          requestStatus: this.RequestStatus.PendingPayroll,
          submitterEmail: o.submitterEmail,
          submitterName: o.submitterName,
          CreatedBy: o.CreatedBy,
          ModifiedBy: this.LoggedInUser.DisplayName,
          Created: o.Created,//moment(new Date()).format("M/D/YYYY"),//new Date(),//
          dateSubmitted: o.dateSubmitted,//moment(new Date()).format("M/D/YYYY"),
          Modified: getFormattedDate(new Date()),
          payrollEndingDate: o.payrollEndingDate,
          isEmployee: o.isEmployee,
          completedDate: o.requestStatus === 'Approved' ? moment(new Date()).format("M/D/YYYY") : null,

          empBusinessUnit: o.Employee.empBusinessUnit,
          empClass: o.Employee.EmployeeClass,
          actionReason: o.Employee.actionReason,
          effectiveDate: o.Employee.effectiveDate,
          empCompany: o.Employee.CompanyID,
          CompanyName: o.Employee.CompanyID,
          empEmail: o.Employee.empEmail,
          empHireDate: o.Employee.HireDate,
          empId: o.Employee.empId,
          empLocation: o.Employee.empLocation,
          empName: o.Employee.empName,
          Name: o.Employee.empName,
          empPosition: o.Employee.empPosition,
          empState: o.Employee.empState,
          HomeState: o.Employee.empState,
          empSupervisor: o.Employee.empSupervisor,
          empTitle: o.Employee.empTitle ? o.Employee.empTitle : o.Employee.empPosition,
          JobDescription: o.Employee.empTitle ? o.Employee.empTitle : o.Employee.empPosition,
          empDepartmentDescription: o.Employee.DepartmentDescription,
          SubmitterComments: o.SubmitterComments,

          supBusinessUnit: o.SuperVisor.empBusinessUnit,
          supClass: o.SuperVisor.empClass,
          supCompany: o.SuperVisor.empCompany,
          CompanyName: o.SuperVisor.empCompany,
          supEmail: o.SuperVisor.empEmail,
          supHireDate: o.SuperVisor.HireDate,
          supEmpId: o.SuperVisor.empId,
          supLocation: o.SuperVisor.empLocation,
          supName: o.SuperVisor.empName,
          supPosition: o.SuperVisor.empPosition,
          supState: o.SuperVisor.empState,
          HomeState: o.SuperVisor.empState,
          supSupervisor: o.SuperVisor.empSupervisor,
          supTitle: o.SuperVisor.empTitle,
          supDepartmentDescription: o.SuperVisor.DepartmentDescription,
          PayRollData: o.PayrollRequestPayHours,

          payrollApproval: '',
          payrollAssignedTo: '',
          payrollComments: '',
          payrollAssignedToEmail: '',

        }

        try {
          var SaveURL = process.env.REACT_APP_ESF_PAYROLLREQUEST;
          let respPayRollReq = await fetch(SaveURL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              Method: "UPDATE",
              requestID: o.requestId,
              PayrollRequestData: reqBodyValue
            }),
          });
          let creationStaus = await respPayRollReq.json();

          if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
            Swal.fire({
              title: "Something went wrong...",
              html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
              icon: "error",
            });
            console.error(creationStaus.error);
          } else {
            if (!Object.keys(creationStaus).includes('error')) {
              Swal
                .fire({
                  title: "Submitted Successfully",
                  text: "Your request has been submitted and is pending for applicable approvals",
                  icon: "success",
                  html: `<a href="/" >Go to Home</a>`,
                  // showCancelButton: true,
                  confirmButtonText: "View Details",
                  //cancelButtonText: "No, cancel!",
                  // reverseButtons: true,
                })
                .then(async (result) => {
                  if (result.isConfirmed) {
                    this.setState({ goToHome: true });;
                  }
                });
            }

          }
          document.getElementById("lblError").innerHTML = "";

          if (appInsights) {
            appInsights.trackEvent({
              name: `Page Submit Compensation Change`,
              properties: {
                TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
              },
            });
          }

        } catch (err) {
          console.log(err);
          //window.location.href = "compensation-change/SuccessError/0";
          document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
          this.setState({
            //saveStatus: "error",
            requestId: 0,
          });
        }

      }
    }
    else {
      document.getElementById("lblError").innerHTML = validation.message;
    }

  }

  handleSubmitToUpdatePayroll = async () => {
    const start = new Date().getTime();
    this.setState({ goToHome: false });
    var validation = this.setFormValidationStatus();// Not Completed

    if (validation.status) {

      //var requestId = Math.floor(Math.random() * 1677721555).toString(16);
      //let reqBodyValue = this.state.PayrollRequestFormValues;
      let o = this.state.PayrollRequestFormValues;
      let reqBodyValue = {
        requestId: o.requestId,
        requestStatus: o.payrollApproval ? o.payrollApproval : o.requestStatus,
        submitterEmail: o.submitterEmail,
        submitterName: o.submitterName,
        CreatedBy: o.CreatedBy,
        ModifiedBy: this.LoggedInUser.DisplayName,
        Created: o.Created,//moment(new Date()).format("M/D/YYYY"),//new Date(),//
        dateSubmitted: o.dateSubmitted,//moment(new Date()).format("M/D/YYYY"),
        Modified: getFormattedDate(new Date()),
        payrollEndingDate: o.payrollEndingDate,
        isEmployee: o.isEmployee,
        completedDate: o.requestStatus === 'Approved' ? moment(new Date()).format("M/D/YYYY") : null,

        empBusinessUnit: o.Employee.empBusinessUnit,
        empClass: o.Employee.EmployeeClass,
        actionReason: o.Employee.actionReason,
        effectiveDate: o.Employee.effectiveDate,
        empCompany: o.Employee.CompanyID,
        CompanyName: o.Employee.CompanyID,
        empEmail: o.Employee.empEmail,
        empHireDate: o.Employee.HireDate,
        empId: o.Employee.empId,
        empLocation: o.Employee.empLocation,
        empName: o.Employee.empName,
        Name: o.Employee.empName,
        empPosition: o.Employee.empPosition,
        empState: o.Employee.empState,
        HomeState: o.Employee.empState,
        empSupervisor: o.Employee.empSupervisor,
        empTitle: o.Employee.empTitle ? o.Employee.empTitle : o.Employee.empPosition,
        JobDescription: o.Employee.empTitle ? o.Employee.empTitle : o.Employee.empPosition,
        empDepartmentDescription: o.Employee.DepartmentDescription,
        SubmitterComments: o.SubmitterComments,

        supBusinessUnit: o.SuperVisor.empBusinessUnit,
        supClass: o.SuperVisor.empClass,
        supCompany: o.SuperVisor.empCompany,
        CompanyName: o.SuperVisor.empCompany,
        supEmail: o.SuperVisor.empEmail,
        supHireDate: o.SuperVisor.HireDate,
        supEmpId: o.SuperVisor.empId,
        supLocation: o.SuperVisor.empLocation,
        supName: o.SuperVisor.empName,
        supPosition: o.SuperVisor.empPosition,
        supState: o.SuperVisor.empState,
        HomeState: o.SuperVisor.empState,
        supSupervisor: o.SuperVisor.empSupervisor,
        supTitle: o.SuperVisor.empTitle,
        supDepartmentDescription: o.SuperVisor.DepartmentDescription,
        PayRollData: o.PayrollRequestPayHours,

        payrollApproval: o.payrollApproval,
        payrollAssignedTo: o.payrollAssignedTo,
        payrollComments: o.payrollComments,
        payrollAssignedToEmail: o.payrollAssignedToEmail,

      }

      try {
        var SaveURL = process.env.REACT_APP_ESF_PAYROLLREQUEST;
        let respPayRollReq = await fetch(SaveURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            Method: "UPDATE",
            requestID: o.requestId,
            PayrollRequestData: reqBodyValue
          }),
        });
        let creationStaus = await respPayRollReq.json();

        if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
          Swal.fire({
            title: "Something went wrong...",
            html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
            icon: "error",
          });
          console.error(creationStaus.error);
        } else {
          if (!Object.keys(creationStaus).includes('error')) {
            Swal
              .fire({
                title: "Submitted Successfully",
                text: "Your request has been submitted and is pending for applicable approvals",
                icon: "success",
                html: `<a href="/" >Go to Home</a>`,
                // showCancelButton: true,
                confirmButtonText: "View Details",
                //cancelButtonText: "No, cancel!",
                // reverseButtons: true,
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  this.setState({ goToHome: true });;
                }
              });
          }

        }

        document.getElementById("lblError").innerHTML = "";

        if (appInsights) {
          appInsights.trackEvent({
            name: `Page Submit Compensation Change`,
            properties: {
              TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
            },
          });
        }

      } catch (err) {
        console.log(err);
        //window.location.href = "compensation-change/SuccessError/0";
        document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
        this.setState({
          //saveStatus: "error",
          requestId: 0,
        });
      }
    } else {
      document.getElementById("lblError").innerHTML = validation.message;
    }

  };

  onClickCancel = async () => {
    const start = new Date().getTime();
    //var requestId = Math.floor(Math.random() * 1677721555).toString(16);
    //let reqBodyValue = this.state.PayrollRequestFormValues;
    let o = this.state.PayrollRequestFormValues;
    let reqBodyValue = {
      requestId: o.requestId,
      requestStatus: 'Cancelled',
      submitterEmail: o.submitterEmail,
      submitterName: o.submitterName,
      CreatedBy: o.CreatedBy,
      ModifiedBy: this.LoggedInUser.DisplayName,
      Created: o.Created,//moment(new Date()).format("M/D/YYYY"),//new Date(),//
      dateSubmitted: o.dateSubmitted,//moment(new Date()).format("M/D/YYYY"),
      Modified: getFormattedDate(new Date()),
      payrollEndingDate: o.payrollEndingDate,
      isEmployee: o.isEmployee,
      completedDate: o.requestStatus === 'Approved' ? moment(new Date()).format("M/D/YYYY") : null,

      empBusinessUnit: o.Employee.empBusinessUnit,
      empClass: o.Employee.EmployeeClass,
      empCompany: o.Employee.CompanyID,
      CompanyName: o.Employee.CompanyID,
      empEmail: o.Employee.empEmail,
      empHireDate: o.Employee.HireDate,
      empId: o.Employee.empId,
      empLocation: o.Employee.empLocation,
      empName: o.Employee.empName,
      Name: o.Employee.empName,
      empPosition: o.Employee.empPosition,
      empState: o.Employee.empState,
      HomeState: o.Employee.empState,
      empSupervisor: o.Employee.empSupervisor,
      empTitle: o.Employee.empTitle ? o.Employee.empTitle : o.Employee.empPosition,
      JobDescription: o.Employee.empTitle ? o.Employee.empTitle : o.Employee.empPosition,
      DepartmentDescription: o.Employee.DepartmentDescription,

      supBusinessUnit: o.SuperVisor.empBusinessUnit,
      supClass: o.SuperVisor.empClass,
      supCompany: o.SuperVisor.empCompany,
      CompanyName: o.SuperVisor.empCompany,
      supEmail: o.SuperVisor.empEmail,
      supHireDate: o.SuperVisor.HireDate,
      supEmpId: o.SuperVisor.empId,
      supLocation: o.SuperVisor.empLocation,
      supName: o.SuperVisor.empName,
      supPosition: o.SuperVisor.empPosition,
      supState: o.SuperVisor.empState,
      HomeState: o.SuperVisor.empState,
      supSupervisor: o.SuperVisor.empSupervisor,
      supTitle: o.SuperVisor.empTitle,
      PayRollData: o.PayrollRequestPayHours,

      payrollApproval: '',
      payrollAssignedTo: '',
      payrollComments: '',
      payrollAssignedToEmail: '',

    }

    try {
      var SaveURL = process.env.REACT_APP_ESF_PAYROLLREQUEST;
      let respPayRollReq = await fetch(SaveURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Method: "UPDATE",
          requestID: o.requestId,
          PayrollRequestData: reqBodyValue
        }),
      });
      let creationStaus = await respPayRollReq.json();

      if (creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT) {
        Swal.fire({
          title: "Something went wrong...",
          html: creationStaus.ResultSets?.Table1?.[0]?.CREATEspRESULT,
          icon: "error",
        });
        console.error(creationStaus.error);
      } else {
        if (!Object.keys(creationStaus).includes('error')) {
          Swal
            .fire({
              title: "Submitted Successfully",
              text: "Your request has been submitted and is pending for applicable approvals",
              icon: "success",
              html: `<a href="/" >Go to Home</a>`,
              // showCancelButton: true,
              confirmButtonText: "View Details",
              //cancelButtonText: "No, cancel!",
              // reverseButtons: true,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.setState({ goToHome: true });;
              }
            });
        }

      }
      document.getElementById("lblError").innerHTML = "";

      if (appInsights) {
        appInsights.trackEvent({
          name: `Page Submit Compensation Change`,
          properties: {
            TimeTaken: `${(new Date().getTime() - start) / 1000} s`,
          },
        });
      }

    } catch (err) {
      console.log(err);
      //window.location.href = "compensation-change/SuccessError/0";
      document.getElementById("lblError").innerHTML = "Technical issue, please try after sometime or contact administrator";
      this.setState({
        //saveStatus: "error",
        requestId: 0,
      });
    }


  }

  setFormValidationStatus() {
    var errorMessage = "";
    var isFormSaveUpdateValid = true, isFormPayrollApprovalValid = true;

    let oFrm = this.state.PayrollRequestFormValues;
    /*Validate Form Employee and Supervisor values*/
    if (oFrm.isEmployee === "" || oFrm.isEmployee === "Select"
      || oFrm.payrollEndingDate === undefined || oFrm.payrollEndingDate === null || oFrm.payrollEndingDate === ""
      || oFrm.Employee.empName === undefined || oFrm.Employee.empName === "" || oFrm.Employee.empCompany === undefined || oFrm.Employee.empCompany === ""
      || oFrm.SuperVisor.empName === undefined || oFrm.SuperVisor.empName === "" || oFrm.SuperVisor.empCompany === undefined || oFrm.SuperVisor.empCompany === ""
      || !this.validateSaturday(moment(oFrm.payrollEndingDate).format("M/D/YYYY"))
    ) {
      isFormSaveUpdateValid = false;
      errorMessage = "Pleaes complete all required fields";
    }

    let oPayHrs = this.state.PayrollRequestFormValues.PayrollRequestPayHours;
    if (isFormSaveUpdateValid) {
      /*validate payroll data - Hourly*/
      if (oFrm.isEmployee === "Hourly") {
        for (let i = 0; i < oPayHrs.length; i++) {

          if (oPayHrs[i].action !== "delete" && (oPayHrs[i].isLuchTaken === undefined || oPayHrs[i].isLuchTaken === null || oPayHrs[i].isLuchTaken === "" || oPayHrs[i].isLuchTaken === "--Select--"
            || oPayHrs[i].reqDate === undefined || oPayHrs[i].reqDate === null || oPayHrs[i].reqDate === ""
            || oPayHrs[i].startTime === "" || oPayHrs[i].endTime === "" || /*oPayHrs[i].lunchOut === "" || oPayHrs[i].lunchIn === "" ||*/ oPayHrs[i].isLuchTaken === ""
            || oPayHrs[i].typeOfPayHourly === "" //|| oPayHrs[i].milageAmount === ""

          )) {
            isFormSaveUpdateValid = false;
            errorMessage = "Pleaes complete all required fields";
            break;
          }
          else if (oPayHrs[i].action !== "delete" && oPayHrs[i].isLuchTaken === "Yes" && (oPayHrs[i].lunchOut === "" || oPayHrs[i].lunchIn === "")) {
            isFormSaveUpdateValid = false;
            errorMessage = "Pleaes complete all required fields";
            break;
          }
        }

      }
      /*validate payroll data - Salary*/
      if (oFrm.isEmployee === "Salary") {
        for (let i = 0; i < oPayHrs.length; i++) {
          if (oPayHrs[i].action !== "delete" && (oPayHrs[i].reqDate === undefined || oPayHrs[i].reqDate === null || oPayHrs[i].reqDate === ""
            || oPayHrs[i].typeOfPaySalary === undefined || oPayHrs[i].typeOfPaySalary === null || oPayHrs[i].typeOfPaySalary === "" || oPayHrs[i].typeOfPaySalary === "--Select--"
            || oPayHrs[i].typeOfDay === undefined || oPayHrs[i].typeOfDay === null || oPayHrs[i].typeOfDay === "" || oPayHrs[i].typeOfDay === "--Select--"
          )) {
            isFormSaveUpdateValid = false;
            break;
          }
        }
      }
    }

    /*Payroll Validation*/
    if (this.state.PayrollRequestFormValues.requestStatus === this.RequestStatus.PendingPayroll) {
      if (!isFormSaveUpdateValid) {
        isFormPayrollApprovalValid = false;
      }
      else if (this.state.PayrollRequestFormValues.payrollApproval === undefined || this.state.PayrollRequestFormValues.payrollApproval === null || this.state.PayrollRequestFormValues.payrollApproval === "") {
        //isFormPayrollApprovalValid = false;
        //errorMessage = "Please complete all required fields";
      } else if (this.state.PayrollRequestFormValues.payrollApproval !== "Pending Review"
        && (this.state.PayrollRequestFormValues.payrollAssignedToEmail === undefined || this.state.PayrollRequestFormValues.payrollAssignedToEmail === null || this.state.PayrollRequestFormValues.payrollAssignedToEmail === "")
      ) {
        isFormPayrollApprovalValid = false;
        errorMessage = "Please complete all required fields";
      }
    }


    this.setState({ isFormSaveUpdateValid: isFormSaveUpdateValid, isFormPayrollApprovalValid: isFormPayrollApprovalValid, errorMessage: "" });
    return ({ status: isFormSaveUpdateValid, errorMessage: errorMessage })

  }

  render() {
    {
      var showUpdateButtonPayroll = false, checkUserRoleInPayroll = false, isPayRollEnabled, showAddDeleteRow = true;
      var CreateOrUpdate = undefined;
      if (!this.state.PayrollRequestFormValues.requestId) {
        CreateOrUpdate = "create"
      }
      else if (this.state.PayrollRequestFormValues.requestId/* &&
      (this.state.RequestStatus === this.RequestStatus.RejectHR
        || this.state.RequestStatus === this.RequestStatus.RejectAccount
        || this.state.RequestStatus === this.RequestStatus.RejectFinance)
      && this.LoggedInUser.IsSubmitter*/
      ) {
        CreateOrUpdate = "resubmit"
      }

      if (this.state.PayrollRequestFormValues.requestStatus === undefined || this.state.PayrollRequestFormValues.requestStatus === null
        || this.state.PayrollRequestFormValues.requestStatus === "" || this.state.PayrollRequestFormValues.requestStatus === this.RequestStatus.RejectToSubmitter) {
        showAddDeleteRow = true;
      } else {
        showAddDeleteRow = false;
      }

      if (this.state.PayrollRequestFormValues.requestStatus === this.RequestStatus.PendingPayroll) {
        showAddDeleteRow = false;
        if (this.roles && this.roles.length > 0) {
          if (this.roles.some(role => (role.name === userRoles.payrollBillable)) || this.roles.some(role => (role.name === userRoles.payrollInternal)) || this.roles.some(role => (role.name === userRoles.payrollCanada))) {
            checkUserRoleInPayroll = true;
          }
          if (this.state.PayrollRequestFormValues.requestStatus === this.RequestStatus.PendingPayroll && this.roles.length > 0 && this.roles[0].name && (checkUserRoleInPayroll || this.LoggedInUser.IsAdminRole)) {

            showUpdateButtonPayroll = true;
          }
        }
        if (showUpdateButtonPayroll &&
          (this.state.PayrollRequestFormValues.requestStatus.toLowerCase().trim() === "complete" || this.state.PayrollRequestFormValues.requestStatus.toLowerCase().trim().indexOf("reject") > 0 || this.state.PayrollRequestFormValues.requestStatus.toLowerCase().trim().indexOf("cancel") > 0)
        ) {
          showUpdateButtonPayroll = false;
        }
      }
      // console.log("Employees", this.state.Employees);
      // console.log("Is form valid", this.state.isFormValid);
      var oPayrollEndingDate = this.state.PayrollRequestFormValues.payrollEndingDate ? moment(this.state.PayrollRequestFormValues.payrollEndingDate).format("YYYY-MM-DD") : null
      return (

        <section>
          <Title title={"Payroll Request Form"} color={"#9A9B9C"} />
          <Note note={"Instructions: This form is to be used to submit a manual payout request for a prior payroll week. If hours need to be deducted from the employee, please reach out to employeeservices@marketsource.com.  Only enter information for one payroll week at a time. If the dates cover multiple payroll weeks, you will need to submit a separate form for each of those weeks. Pay week is Sunday through Saturday."} />
          {(this.state.isCancelVisible &&
            this.state.PayrollRequestFormValues.requestStatus !== "Approve" &&
            this.state.PayrollRequestFormValues.requestStatus !== "Cancelled") &&
            <>
              <br />
              {this.state.isNavigateToApprovalQueue && (<Navigate to={`/approval-queue/`} replace={true}></Navigate>)}
              <SubmitButton label="Cancel This Form" onClick={this.onClickCancel} color={"#A51D25"} />

            </>
          }
          {(this.state.isESFOwner || (this.state.loggedInAs.EmpId)) && (
            <LoginAs
              onChange={(e) => this.getImpersonated(e)}
              clearLoginAs={(e) => this.clearImpersonation(e)}
              showError={this.state.LoginAsError}
              empId={this.state.loggedInAs.EmpId}
              displayName={this.state.loggedInAs.DisplayName}
              email={this.state.loggedInAs.Email}
              requestId={this.state.requestId}
              loading={this.state.loading}
            //defaultSelectedUser={this.state.loggedInAs.DisplayEmail}
            //loginAsThisUser={() => this.loginAsOtherUser()}

            ></LoginAs>)}

          <label id="lblError" name="lblError"></label>

          {!this.state.requestId && <EsfInput
            label="Employee's Location"
            name="employeeCompany"
            type="select"
            value={this.state.PayrollRequestFormValues.Employee.CompanyName}
            options={this.state.EmployeeCompanyLocations}
            onChange={(e) => this.handleCompanyCountryChangeEmp(e)}

            required
            disabled={this.state.requestId ? true : false}
          />
          }

          <EmployeeSearch
            name='emp'
            employees={this.state.EmployeeList}
            selectedEmployee={this.state.SelectedEmployee}
            //selectedEmployee={this.state.PayrollRequestFormValues.Employee}
            setSelectedEmployee={(e) => this.handleSelectedEmployee(e)}
            searchQuery={this.state.searchQueryEmp}
            setSearchQuery={(e) => this.handleSetSearchQueryEmp(e)}
            loading={this.state.isEmployeeLoading}
            headerExpand
            disabled={this.state.requestId ? true : false}
          />
          {!this.state.SelectedEmployee && (
            <label className="labelNormal">{employeeSearchText.message}</label>
          )}


          {!this.state.requestId && <EsfInput
            label="Supervisor's Location"
            name="supervisorCompany"
            type="select"
            value={this.state.PayrollRequestFormValues.SuperVisor.CompanyName}
            options={this.state.EmployeeCompanyLocations}
            onChange={(e) => this.handleCompanyCountryChangeSup(e)}

            required
            disabled={this.state.requestId ? true : false}
          />
          }

          <EmployeeSearch
            name='sup'
            employees={this.state.SuperVisorList}
            selectedEmployee={this.state.SelectedSupervisor}
            //selectedEmployee={this.state.PayrollRequestFormValues.SuperVisor}
            setSelectedEmployee={(e) => this.handleSelectedSupervisor(e)}
            searchQuery={this.state.searchQuerySup}
            setSearchQuery={(e) => this.handleSetSearchQuerySup(e)}
            loading={this.state.isSupervisorLoading}
            headerExpand
            supervisor
            disabled={this.state.requestId ? true : false}
          />
          {!this.state.SelectedEmployee && (
            <label className="labelNormal">{employeeSearchText.message}</label>
          )}


          <EsfInput
            type="date"
            name="payrollEndingDate"
            label="Payroll Week Ending Date: "
            alert="Saturday only"
            required
            value={oPayrollEndingDate}
            onChange={(e) => this.setComponentStatus(e)}
          />
          <label className={this.state.dateLblClass} id="lblSundayOnly" name="lblSundayOnly">&nbsp;Saturday only</label>
          <EsfInput
            type="select"
            name="isEmployee"
            label="Is this employee:"
            required
            value={this.state.PayrollRequestFormValues.isEmployee}
            onChange={(e) => this.setComponentStatus(e)}
            options={this.isEmployeeOptions}
          />

          {(this.state.PayrollRequestFormValues.isEmployee === 'Hourly') && (
            this.state.PayrollRequestFormValues.PayrollRequestPayHours.map((o, index) =>
              <DayHourly key={o.AutoID}

                Notes={'NOTE: Add new day by clicking "Add new row"button'}
                requestId={o.requestId ? o.requestId : ''}
                AutoID={o.AutoID === "" ? String(index + 1) : String(o.AutoID)}
                reqDate={o.reqDate ? moment(o.reqDate).format("YYYY-MM-DD") : null}
                startTime={o.startTime ? o.startTime : ''}
                endTime={o.endTime ? o.endTime : ''}
                lunchOut={o.lunchOut ? o.lunchOut : ''}
                lunchIn={o.lunchIn ? o.lunchIn : ''}
                isLuchTaken={o.isLuchTaken ? o.isLuchTaken : ''}
                typeOfPayHourly={o.typeOfPayHourly ? o.typeOfPayHourly : ''}
                milageAmount={o.milageAmount ? o.milageAmount : ''}
                salaryOrHourly={o.salaryOrHourly ? o.salaryOrHourly : 'Hourly'}
                setValuesPayHourOnChange={(e) => this.setValuesPayHourOnChange(e, o.AutoID === "" ? String(index + 1) : String(o.AutoID))}
                setDeleteRow={(e) => this.setDeleteRow(e, o.AutoID === "" ? String(index + 1) : String(o.AutoID))}
                action={o.action}
                showLunchinOut={this.state.PayrollRequestFormValues.PayrollRequestPayHours[index].isLuchTaken === "Yes" ? true : false}
                showDelete={showAddDeleteRow}

              ></DayHourly>



            )

          )
          }

          {(this.state.PayrollRequestFormValues.isEmployee === 'Salary') && (
            this.state.PayrollRequestFormValues.PayrollRequestPayHours.map((o, index) =>
              <DaySalary key={o.AutoID}
                Notes='NOTE: Add new day by clicking "Add new row"button'
                requestId={o.requestId}
                reqDate={o.reqDate ? moment(o.reqDate).format("YYYY-MM-DD") : null}
                typeOfPaySalary={o.typeOfPaySalary}
                typeOfDay={o.typeOfDay}
                salaryOrHourly='Salary'
                setValuesPayHourOnChange={(e) => this.setValuesPayHourOnChange(e, o.AutoID === "" ? String(index) + 1 : String(o.AutoID))}
                setDeleteRow={(e) => this.setDeleteRow(e, o.AutoID === "" ? String(index + 1) : String(o.AutoID))}
                action={o.action}
                showDelete={showAddDeleteRow}
              >
              </DaySalary>
            )

          )
          }


          <div style={{ textAlign: 'right', marginBottom: '10px' }} >
            {(showAddDeleteRow && (this.state.PayrollRequestFormValues.isEmployee === "Hourly" || this.state.PayrollRequestFormValues.isEmployee === "Salary")) && (
              <button type="submit"
                onClick={(e) => this.addBlankRowToPayrollRequestPayHours(e)} style={{ color: 'white', backgroundColor: '#0195d3', width: '150px', height: '35px' }}>
                Add new row
              </button>
            )}
          </div>
          <EsfInput
            type="textarea"
            placeHolder="Add any additional comments…"
            name="SubmitterComments"
            value={this.state.PayrollRequestFormValues.SubmitterComments}
            onChange={(e) => this.setComponentStatus(e)}
          />
          {this.state.goToHome && (<Navigate to={`/payroll-request/view/${this.state.PayrollRequestFormValues.requestId}`} replace={true}></Navigate>)}
          {(!this.state.PayrollRequestFormValues.requestId
            || this.state.PayrollRequestFormValues.requestStatus === this.RequestStatus.RejectToSubmitter
          ) && (
              <SubmitButton
                label={this.state.isFormSaveUpdateValid ? "Submit" : (<span>Please complete all required fields.</span>)}
                onClick={this.state.isFormSaveUpdateValid ? () => this.handleSubmitToSave(CreateOrUpdate) : null}
              />
            )}
          {(this.state.PayrollRequestFormValues.requestStatus || this.state.PayrollRequestFormValues.requestStatus === this.RequestStatus.PendingPayroll) && (< Approval approvalFor="payroll" assignToLabel="Assigned To" assignToName="payrollassignto" assignToValue={this.state.PayrollRequestFormValues.payrollAssignedToEmail} assignToOnChange={(e) => this.setComponentStatus(e)}
            statusName="payrollapprovalstatus" statusLabel="Approval" statusValue={this.state.PayrollRequestFormValues.payrollApproval} statusOnChange={(e) => this.setComponentStatus(e)}
            additionalCommName="payrolladdcomm" additionalCommValue={this.state.PayrollRequestFormValues.payrollComments} additionalCommOnChange={(e) => this.setComponentStatus(e)}
          ></Approval>)}
          {(showUpdateButtonPayroll) && (<SubmitButton
            onClick={this.state.isFormPayrollApprovalValid ? () => this.handleSubmitToUpdatePayroll() : null}
            label={this.state.isFormPayrollApprovalValid ? "Submit" : (<span>Please complete all required fields. Payroll</span>)}
          />)}
          <br />
          <br />
          {this.state.gridRows.length > 0 &&
            <div className="outerDiv">
              <div>
                <p>{workflowHistory.title}</p>
                <Grid
                  rowData={this.state.gridRows}
                  columnDefs={this.state.gridColumns}
                />
              </div>
            </div>
          }

        </section >

      );
    }

  }

}
//const CompensationChangetWithRouter = withRouter(CompensationChange);

export default PayrollRequest;
