import React, { useEffect, useState } from "react";
import "./styles.css";
import Tile from "../../components/Tile";
import TerminationIcon from "../../assets/images/termination.svg";
import SupervisorChangeIcon from "../../assets/images/supervisorChange.svg";
import CompensationChangeIcon from "../../assets/images/compensationChange.svg";
import PositionChangeIcon from "../../assets/images/positionChange.svg";
import AddProgramIcon from "../../assets/images/addProgram.svg";
import TransferIcon from "../../assets/images/transfer.svg";
import payroll from "../../assets/images/payroll.svg";
import { bgColorsTitle } from "../../utils/constants";
import { FiArrowRight } from "react-icons/fi";
import { postApi } from "../../utils/fetchApi";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const LandingPage = () => {
  const { instance } = useMsal();
  const [activeRequests, setActiveRequests] = useState({
    AddProgram: 0,
    CompensationChange: 0,
    PositionChange: 0,
    SupervisorChange: 0,
    Termination: 0,
    TransferRequests: 0,
    PayrollRequestCount: 0,
  });
  useEffect(() => {
    // setTimeout(() => {
    //   setActiveRequests({
    //     termination: {
    //       count: 22,
    //     },
    //   });
    // }, 3000);

    const fetchData = async () => {
      try {
        const account = await instance.getActiveAccount();
        const response = await postApi(
          process.env.REACT_APP_ESF_ACTIVE_REQUEST,
          {
            currentUserEmail: account.username,
            activeUserDetail: "LandingPage",
          }
        );
        const data = await response;
        setActiveRequests({ ...data.ResultSets?.Table1[0] });
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <main className="landingPage">
      <section>
        <Tile
          title="Termination"
          color="#A51D25"
          url="/termination"
          icon={TerminationIcon}
        ></Tile>
        {activeRequests.Termination > 0 && (
          <Link to="/request-history/no-filter/termination">
            <span>
              {`${activeRequests.Termination} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
      <section>
        <Tile
          title="Supervisor Change"
          color="#021a32"
          url="/supervisor-change"
          icon={SupervisorChangeIcon}
        ></Tile>
        {activeRequests.SupervisorChange > 0 && (
          <Link to="/request-history/no-filter/supervisor-change">
            <span>
              {`${activeRequests.SupervisorChange} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
      <section>
        <Tile
          title="Compensation Change"
          color="#4d4f53"
          url="/compensation-change"
          icon={CompensationChangeIcon}
        ></Tile>
        {activeRequests.CompensationChange > 0 && (
          <Link to="/request-history/no-filter/compensation-change">
            <span>
              {`${activeRequests.CompensationChange} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
      <section>
        <Tile
          title="Position Change"
          color="#f8971d"
          url="/position-change"
          icon={PositionChangeIcon}
        ></Tile>
        {activeRequests.PositionChange > 0 && (
          <Link to="/request-history/no-filter/position-change">
            <span>
              {`${activeRequests.PositionChange} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
      <section>
        <Tile
          title="Add Program"
          color={bgColorsTitle["add-Program"]}
          url="/add-program"
          icon={AddProgramIcon}
        ></Tile>
        {activeRequests.AddProgram > 0 && (
          <Link to="/request-history/no-filter/add-program">
            <span>
              {`${activeRequests.AddProgram} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
      <section>
        <Tile
          title="Transfer"
          color="#8dc63f"
          url="/transfer"
          icon={TransferIcon}
        ></Tile>
        {activeRequests.TransferRequests > 0 && (
          <Link to="/request-history/no-filter/transfer">
            <span>
              {`${activeRequests.TransferRequests} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
      <section></section>
      <section>
        <Tile
          title="Payroll Request"
          color="#A4D7F4"
          url="/payroll-request"
          icon={payroll}
        ></Tile>
        {activeRequests.PayrollRequestCount > 0 && (
          <Link to="/request-history/no-filter/payroll-request">
            <span>
              {`${activeRequests.PayrollRequestCount} active requests `}
              <FiArrowRight />
            </span>
          </Link>
        )}
      </section>
    </main>
  );
};

export default LandingPage;
