import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Title";
import { AiOutlineBarcode } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import moment from "moment";
import "./styles.css";
import { bgColorsTitle, formRoutes } from "../../utils/constants";

const SubmittedDetails = () => {
  const { requestId, form } = useParams();
  const [formData, setFormData] = useState(null);
  const [titleColor, setTitleColor] = useState("#A51D25");
  const ReasonLabels = {
    termination: "Reason for Termination",
    "compensation-change": "Reason for pay change",
    "position-change": "Reason for Position Change ",
  };
  const DateLabels = {
    termination: "Termination Date",
    "compensation-change": "Effective Date",
    "add-program": "Effective Date",
    "position-change": "Effective Date",
    transfer: "Effective Date",
    "supervisor-change": "Effective Date",
    "payroll-request": "Effective Date",
  };
  const PhoneLabels = {
    termination: "Your Phone Number",
    "compensation-change": "Your Phone Number",
    "add-program": "Your Phone Number",
    "position-change": "Your Phone Number",
    transfer: "Your phone Number",
    "supervisor-change": "Your Phone Number",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (requestId != null && requestId != "") {
      let callUrl = process.env.REACT_APP_ESF_TERMINATION;
      let color = titleColor;
      switch (form) {
        case formRoutes.addProgram:
          callUrl = process.env.REACT_APP_ESF_ADDPROGRAM;
          color = bgColorsTitle["add-Program"];
          break;
        case "compensation-change":
          callUrl = process.env.REACT_APP_ESF_COMPENSATIONCHANGE;
          color = bgColorsTitle["compensation-change"];
          break;
        case "transfer":
          callUrl = process.env.REACT_APP_ESF_TRASFER;
          color = bgColorsTitle["transfer"];
          break;
        case formRoutes.positionChange:
          callUrl = process.env.REACT_APP_ESF_POSITIONCHANGE;
          color = bgColorsTitle["position-change"];
          break;
        case formRoutes.supervisorChange:
          callUrl = process.env.REACT_APP_ESF_SUPERVISORCHANGE;
          color = bgColorsTitle["supervisor-change"];
          break;
        case "payroll-request":
          callUrl = process.env.REACT_APP_ESF_PAYROLLREQUEST;
          color = bgColorsTitle["compensation-change"];
          break;
        default:
          break;

      }
      const response = await fetch(callUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Method: "READ",
          requestID: requestId ? requestId : "",
        }),
      });
      const data = await response.json();
      setTitleColor(color);
      setFormData(data.ResultSets.Table1?.[0]);
    }
  };

  return (
    <div>
      {formData && <>
        <Title
          title={
            <>
              Request
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {formData.requestId}{" "}
              </span>
              Details
            </>
          }
          color={titleColor}
        />
        {formData.empName && (
          <div className="dataRow">
            <div className="nameAndPosition">
              <span className="blue">{formData.empName} </span>
              {formData.empPosition && <span>{formData.empPosition}</span>}
            </div>
            <div className="idAndLocation">
              <span>
                <AiOutlineBarcode /> {formData.empId}
              </span>
              <span>
                <MdLocationOn /> {formData.empState}
              </span>
            </div>
          </div>
        )}
        {formData.actionType && (
          <div className="dataRow">
            <span>Action</span>
            <span>{formData.actionType}</span>
          </div>
        )}

        {(formData.effectiveDate && form !== "payroll-request") && (
          <div className="dataRow">
            <span>{DateLabels[form]}</span>
            <span>
              {moment(formData.effectiveDate).format("MM/DD/YYYY")}
            </span>
          </div>
        )}
        {formData.programToAdd && (
          <div className="dataRow">
            <span>Program to Add</span>
            <span>{formData.programToAdd}</span>
          </div>
        )}
        {formData.newSupervisorName && (
          <div className="dataRow">
            <span>New Supervisor</span>
            <span>{formData.newSupervisorName}</span>
          </div>
        )}
        {formData.newJobTitle && (
          <div className="dataRow">
            <span>New Job Title</span>
            <span>{formData.newJobTitle}</span>
          </div>
        )}
        {formData.programName && (
          <div className="dataRow">
            <span>Program/Department Name</span>
            <span>{formData.programName}</span>
          </div>
        )}

        {formData.newLocation && (
          <div className="dataRow">
            <span>Employee Future Location</span>
            <span>{formData.newLocation}</span>
          </div>
        )}
        {formData.compensationType && (
          <div className="dataRow">
            <span>How is pay entered?</span>
            <span>{formData.compensationType}</span>
          </div>
        )}
        {formData.payRate && (
          <div className="dataRow">
            <span>Pay Rate</span>
            <span>{formData.payRate}</span>
          </div>
        )}
        {formData.bonusFrequency && (
          <div className="dataRow">
            <span>Bonus Frequency</span>
            <span>{formData.bonusFrequency}</span>
          </div>
        )}
        {formData.bonusPotential && (
          <div className="dataRow">
            <span>Annualized Bonus Potential</span>
            <span>{formData.bonusPotential}</span>
          </div>
        )}
        {formData.currentAnnualSalary && (
          <div className="dataRow">
            <span>Current Annual Salary</span>
            <span>{formData.currentAnnualSalary}</span>
          </div>
        )}
        {formData.currentAnnualVariablePotential && (
          <div className="dataRow">
            <span>Current Annual Variable Potential</span>
            <span>{formData.currentAnnualVariablePotential}</span>
          </div>
        )}
        {formData.totalPercentChange && (
          <div className="dataRow">
            <span>Total Percent Change</span>
            <span>{formData.totalPercentChange}</span>
          </div>
        )}
        {formData.justificationForPromotion && (
          <div className="dataRow">
            <span>Justification for Promotion </span>
            <span>{formData.justificationForPromotion}</span>
          </div>
        )}
        {formData.alignmentToOtherSimilarEmployees && (
          <div className="dataRow">
            <span>Alignment to other similar employees holding the same role</span>
            <span>{formData.alignmentToOtherSimilarEmployees}</span>
          </div>
        )}
        {formData.emailAccountChangeRequiredPTtoFTorFTtoPT && <div className="dataRow">
          <span>
            Is the employee changing from PT to FT or FT to PT?
          </span>
          <span>{formData.emailAccountChangeRequiredPTtoFTorFTtoPT}</span>
        </div>}

        {formData.businessTitle && (
          <div className="dataRow">
            <span>Business Title</span>
            <span>{formData.businessTitle}</span>
          </div>
        )}

        {formData.submitterPhone && (
          <div className="dataRow">
            <span>{PhoneLabels[form]}</span>
            <span>{formData.submitterPhone}</span>
          </div>
        )}
        {formData.actionComments && (
          <div className="dataRow">
            <span>Action Comments</span>
            <span>{formData.actionComments}</span>
          </div>
        )}
        {formData.returnAsset && (
          <div className="dataRow">
            <span>How will assets be returned?</span>
            <span>{formData.returnAsset}</span>
          </div>
        )}
        {formData.payrollEndingDate && (
          <div className="dataRow">
            <span>Payroll Week Ending Date</span>
            <span>{formData.payrollEndingDate}</span>
          </div>
        )}
        {formData.isEmployee && (
          <div className="dataRow">
            <span>Is this employee</span>
            <span>{formData.isEmployee}</span>
          </div>
        )}
        {formData.supName && (
          <div className="dataRow">
            <div className="nameAndPosition">
              <span className="blue">{formData.supName} </span>
              {formData.empPosition && <span>{formData.supPosition}</span>}
            </div>
            <div className="idAndLocation">
              <span>
                <AiOutlineBarcode /> {formData.supEmpId}
              </span>
              <span>
                <MdLocationOn /> {formData.supState}
              </span>
            </div>
          </div>
        )}

      </>}


    </div>
  );
};

export default SubmittedDetails;
